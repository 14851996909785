import { Vue, Options } from 'vue-class-component'
import { useRoute } from 'vue-router'
import { Watch } from 'vue-property-decorator'
import { TodayMatch } from '@/services/TodaysMatchsDataService'
import ClubsDataService from '@/services/ClubsDataService'
import SysClub from '@/types/SysClub'

type dataReturnType = { klubbers: any; }

@Options({
})
export default class todaysMatchScheduleList extends Vue {
  $Message: any
  private todayMatchData: { [key: string]: any } = {}
  private forFilterMatchData: { [key: string]: any } = {}

  private klubbers: SysClub[] = []
  filterByClubOptions: string[] = []
  pageSizeValue = 25
  memberSearchTerm = ''
  clubFilter = ''
  memberSearchInputValue = ''
  filterByClubValue = 'Vælg klub'
  error: any = null
  data (): dataReturnType {
    return {
      klubbers: this.klubbers
    }
  }

  @Watch('filterByClubValue')
  updateLivematch () {
    if (this.filterByClubValue !== 'Vælg klub') {
      this.todayMatchData = this.filterData()
    }
  }

  public filterData () {
    const filteredMatches: any = {}
    for (const divisionName in this.forFilterMatchData) {
      for (const subDivission in this.forFilterMatchData[divisionName]) {
        this.forFilterMatchData[divisionName][subDivission].forEach((element: any) => {
          if (element.awayClubName === this.filterByClubValue || element.homeClubName === this.filterByClubValue) {
            if (filteredMatches?.[divisionName]?.[subDivission]) {
              filteredMatches[divisionName][subDivission].push(element)
            } else {
              filteredMatches[divisionName] = {}
              filteredMatches[divisionName][subDivission] = []
              filteredMatches[divisionName][subDivission].push(element)
            }
          }
        })
      }
    }
    return filteredMatches
  }

  public async getTodayMatches () : Promise<void> {
    await TodayMatch.TodayMatchDataService.getTodayMatches()
      .then((response) => {
        console.log('LIVE_MATCHES_RESPONSE...', response)
        this.todayMatchData = response.data
        this.forFilterMatchData = response.data
      })
      .catch((error) => {
        console.log('LIVE_MATCHES_ERROR...', error)
      })
  }

  public columnSort (sortBy: string) : void {
    if (sortBy === 'reset') {
      this.filterByClubValue = 'Vælg klub'
      this.todayMatchData = this.forFilterMatchData
    }
  }

  public findMember () : void {
    // console.log('Searching members.')
    this.memberSearchTerm = this.memberSearchInputValue
  }

  public fillClubOptions () : void {
    this.filterByClubOptions = []

    for (const klub of this.klubbers) {
      this.filterByClubOptions.push(klub.klubber_klubnavn)
    }

    // console.log(this.filterByClubOptions)
  }

  public retrieveClubs () : void {
    // console.log('Fetching active clubs from API.')

    ClubsDataService.getAll('', null, 'klubber_status=true')
      .then((response) => {
        this.klubbers = response.data
        // console.log(this.klubbers)
        this.fillClubOptions()
      })
      .catch((err) => {
        this.error = err
      })
  }

  async mounted () : Promise<void> {
    this.getTodayMatches()
    this.retrieveClubs()
  }
}
